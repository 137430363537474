const url = {
  dev: {
    // 本地调试ip
    publicUrl: '/baseUrl',
    manageUrl: '/baseUrl',
  },
  pro: {
    // 正式服
    publicUrl: `https://food.nuoyayunpingtai.com/food`,
    manageUrl: `https://food.nuoyayunpingtai.com/food`,
    // publicUrl: `https://foodtest.nuoyayunpingtai.com/food`,
    // manageUrl: `https://foodtest.nuoyayunpingtai.com/food`,
  },
  pro_test: {
    // 测试服
    publicUrl: `https://foodtest.nuoyayunpingtai.com/food`,
    manageUrl: `https://foodtest.nuoyayunpingtai.com/food`,
  },
}

export const get = () => {
  if (process.env.NODE_ENV === 'production') {
    return url['pro']
  } else if (process.env.NODE_ENV === 'test') {
    return url['pro_test']
  } else {
    return url['dev']
  }
}
